<template>
  <div class="culture">
    <img src="../../../assets/imges/index/26.png" class="headerimg" />
    <div class="cultureheader">
      <span class="title1">企业介绍</span><span class="arrows">></span>
      <span class="title2" @click.prevent="custormAnchor('synopsis')"
        >邦伲德简介</span
      >
      <span class="title2" @click.prevent="custormAnchor('purpose')"
        >发展历程</span
      >
      <span class="title2" @click.prevent="custormAnchor('spirit')"
        >荣誉资质</span
      >
      <span class="title2" @click.prevent="custormAnchor('environment')"
        >公司环境</span
      >
      <span class="title2" @click.prevent="custormAnchor('framework')"
        >组织架构</span
      >
    </div>
    <div class="construction" id="synopsis">
      <div class="target">
        <div class="header">邦伲德简介</div>
        <div class="targetcard">
          <div class="targetinfo">
            <div class="targetinfoheader">
              <div class="targetinftitle">BONNIDEE</div>
              <div class="targetinftitle2">邦伲德(上海)网络技术有限公司</div>
            </div>
            <div class="targetinfotext">
              <div class="text1">
                邦伲德（上海）网络技术有限公司是云计算、大数据及中国智能化互联网技术开发企业。致力于以在线服务的方式，提供安全、可靠的计算和数据处理能力；为社会各个行业提供最安全、高性能、智能的计算和数据处理服务，正向云计算、大数据、智慧城市运营服务商开拓，打造“云+数”新型AI互联网企业
                。
              </div>
              <div class="text2">
                邦伲德成立于2015年，注册资金1.6亿元人民币，总部坐落于互联网高度发达的上海，办公场所占地面积高达1600多平方米。
              </div>
              <div>
                公司成立6年来，规模不断壮大：拥有员工200+、合作媒体1000+、渠道资源3000+、服务项目案例1万+、触达用户1亿+，旗下直属公司3家、直属分公司2家、控股公司2家、对外投资公司11家，对外投资金额达到3718万元，投资领域涉及科技推广和应用服务业、商务服务业、软件和信息技术服务业。
              </div>
            </div>
          </div>
          <img
            src="../../../assets/imges/presentation/1.png"
            class="targetimg"
          />
        </div>
      </div>
    </div>

    <div class="purpose" id="purpose">
      <div class="purposeheader">发展历程</div>
      <div class="purposeinfo">
        <el-steps align-center>
          <el-step
            :title="item.year"
            v-for="(item, index) in timelist"
            :key="index"
            :class="time"
            @click.native="gettimelist(item, index)"
          >
            <template slot="icon">
              <div>
                <!--  -->
                <div
                  :style="time == item.year ? 'background-color: #007AFF;' : ''"
                  class="circle"
                ></div>
              </div>
            </template>
          </el-step>
        </el-steps>
      </div>
    </div>
    <div style="background: #f5f5f9" id="certification">
      <div class="list">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in timeinfolist"
              :key="index"
              :data-hash="item.yearInfo"
              @mouseenter="over2(item)"
              @mouseleave="over()"
            >
              <div
                class="year"
                :style="item.yearInfo == year ? 'color:#007BFF' : ''"
              >
                {{ item.yearInfo }}
              </div>
              <div
                class="month"
                v-for="(info, index) in item.children"
                :key="index"
              >
                <span class="monthte">{{ info.day || '错误'}}月</span>
                <div
                  class="monthinfo"
                  style="display: inline-block; vertical-align: top"
                >
                  <div class="radu"></div>
                  <div class="monthtext">
                    {{ info.content }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="spirit" id="spirit">
      <div class="header">荣誉资质</div>
      <div
        style="
          display: flex;
          justify-content: center;
          justify-content: center;
          width: 78%;
          margin: 0 auto;
        "
      >
        <div
          v-for="(item, index) in QualificationTypesList"
          :key="index"
          style="display: inline-block; width: 20%"
          :class="QualificationTypeId == item.id ? 'backgauge' : ''"
        >
          <span style="cursor: pointer" @click="updateid(item.id)">{{
            item.name
          }}</span>
        </div>
      </div>
      <div class="spiritinfo">
        <div
          v-for="(item, index) in dynamicinfolist"
          :key="index"
          style="display: inline-block; width: 25%"
          class="dynamicinfolist"
        >
          <el-image :src="item.fileId" class="spiritimg">
            <div slot="error" class="image-slot">
              <img src="../../../assets/imges/error/2.png" class="errimg" />
            </div>
          </el-image>
          <div>{{ item.name }}</div>
        </div>
      </div>
      <div class="certificationbutton">
        <el-button type="primary" @click="toZizhi" class="customization"
          >查看更多</el-button
        >
      </div>
    </div>
    <div style="background: #f5f5f9" id="environment">
      <div class="header">公司环境</div>
      <div class="environmentimg">
        <div class="mastermap">
          <img :src="corporateEnvironmentlist.mainPicture" />
        </div>
        <div class="master">
          <div>
            <img :src="corporateEnvironmentlist.pictureOne" />
            <img :src="corporateEnvironmentlist.pictureTwo" />
          </div>
          <div class="imgon">
            <img :src="corporateEnvironmentlist.pictureThree" />
            <img :src="corporateEnvironmentlist.pictureFour" />
          </div>
        </div>
      </div>
    </div>
    <div id="framework" style="text-align: center">
      <div class="header">组织架构</div>
      <el-image
        :src="require('../../../assets/imges/presentation/3.png')"
      ></el-image>
      <span class="amplification" @click="previewPic()">
        <img src="../../../assets/imges/presentation/4.png" />
        <span class="text">查看大图</span>
        <el-image-viewer
          :url-list="[require('../../../assets/imges/presentation/3.png')]"
          v-if="imgshow"
          :on-close="onclose"
        />
      </span>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Scrollbar } from "swiper";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { SwiperSlide } from "swiper/vue";
import "swiper/swiper.less";
Swiper.use([Navigation, Pagination, Scrollbar]);
import {
  QualificationsQuery,
  QualificationTypesList,
  DevelopmentCourseList,
  CompanyEnvironmentList,
} from "../../../api/index.js";
import { monthtim } from "../../../utils/util.js";
export default {
  data() {
    return {
      imgshow: false,
      // 资质类别列表
      QualificationTypesList: [],
      // 类别id
      QualificationTypeId: "",
      // 资质列表
      dynamicinfolist: [],
      // 发展历程时间列表
      timelist: [],
      // 发展历程信息列表
      timeinfolist: [],
      // 发展历程id
      time: "1",
      // 公司环境
      corporateEnvironmentlist: {},

      year: "",
    };
  },
  components: {
    ElImageViewer,
  },
  created(){
     DevelopmentCourseList().then((res) => {
      let data = res.data.rows;
      let that = this;
      data.forEach(function (item, index) {
        that.timelist.push({
          year: item.yearInfo,
          index,
        });
        item.children.forEach(function (info, index) {
          info.year = info.yearMonth.split("/")[0];
          // info.day = String(monthtim(new Date(info.yearMonth)))
          info.day=info.yearMonth.split("/")[1]
          if(info.day[0] == 0){
            info.day = info.day.slice(1)
          }
        });
      });
      this.timeinfolist = data;
      this.time = this.timelist[0].year;
    });
  },
  mounted() {
    QualificationTypesList({
      PageNo: 1,
      PageSize: 5,
    }).then((res) => {
      this.QualificationTypesList = res.data.rows;
      this.QualificationTypeId = res.data.rows[0].id;
      this.getlist();
    });

   

    CompanyEnvironmentList().then((res) => {
      this.corporateEnvironmentlist = res.data[0];
    });
    this._initSwiper();
  },
  methods: {
    over2(info) {
      this.year = info.yearInfo;
    },
    over() {
      this.year = "";
    },
    custormAnchor(index) {
      //锚点滚动到固定位置
      let anchorElement = document.getElementById(index);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    previewPic() {
      document.querySelector("body").setAttribute("style", "overflow: hidden;");
      this.imgshow = !this.imgshow;
    },
    onclose() {
      this.imgshow = !this.imgshow;
      document.querySelector("body").removeAttribute("style");
    },
    updateid(id) {
      this.QualificationTypeId = id;
      this.getlist();
    },
    getlist() {
      QualificationsQuery({
        QualificationTypeId: this.QualificationTypeId,
        PageNo: 1,
        PageSize: 8,
      }).then((res) => {
        this.total = res.data.totalRows;
        this.dynamicinfolist = res.data.rows;
      });
    },
    _initSwiper() {
      this.swiper = new Swiper(".swiper-container", {
        slideToClickedSlide: true,
        observeParents: true,
        observer: true,
        hashNavigation: true,
        slidesPerView: 3,
        spaceBetween: 21,
        // navigation: {
        // 	nextEl: ".swiper-button-next",
        // 	prevEl: ".swiper-button-prev",
        // },
      });
    },
    // 资质更多
    toZizhi() {
      this.$router.push("Certification");
    },
    gettimelist(val, index) {
      this.time = val.year;
      this.swiper.slideTo(index, 1000, false);
    },
  },
};
</script>

<style scoped lang="less">
a {
  text-decoration: none;
}

.customization {
  background: linear-gradient(270deg, #0a89ff 0%, #4371ff 100%);
}

#framework {
  padding-bottom: 56px;

  .amplification {
    cursor: pointer;
    img {
      width: 21px;
      height: 23px;
      padding-right: 14px;
      vertical-align: middle;
    }
    .text {
      vertical-align: middle;
      color: #1871fc;
      font-size: 22px;
      font-weight: 500;
      line-height: 33px;
      border-bottom: 2px solid #1871fc;
    }
  }
}

.environmentimg {
  width: 80%;
  margin: 0 auto;
  padding-bottom: 100px;

  .mastermap {
    display: inline-block;
    width: 518px;
    height: 674px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .master {
    display: inline-block;
    margin-left: 23px;

    .imgon {
      margin-top: 15px;
    }

    img {
      width: 416px;
      height: 326px;
      margin-left: 23px;
    }
  }
}

.spirit {
  width: 80%;
  margin: 0 auto;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;

  .backgauge {
    color: #1d74fc;
  }

  .certificationbutton {
    text-align: center;
    margin-top: 65px;
    padding-bottom: 79px;
  }

  .spiritinfo {
    padding-top: 73px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;

    .dynamicinfolist {
      margin-bottom: 44px;

      .spiritimg {
        min-width: 305px;
        min-height: 212px;
        max-height: 500px;
        max-width: 305px;
        margin-bottom: 30px;
      }
    }
  }
}

.list {
  width: 80%;
  margin: 0 auto;
  padding-top: 36px;
  padding-bottom: 101px;

  .year {
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
    padding-bottom: 18px;
    border-bottom: 1px solid #d5d5d5;
  }

  .month {
    font-size: 14px;
    font-weight: 500;
    color: #828282;
    line-height: 21px;
    margin-top: 17px;
		 span {
        display: inline-block;
        vertical-align: top;
				min-width: 30px;
      }
    .monthinfo {
      // border-left: 1px dashed #E8E8E8;
      margin-left: 25px;
      padding-left: 12px;
      .monthtext {
        width: 356px;
      }
			span {
        display: inline-block;
        vertical-align: top;
      }
      .radu {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: #e8e8e8;
        border-radius: 50%;
        left: 42px;
        margin-top: 2px;
      }
    }
  }
}

.header {
  text-align: center;
  font-size: 36px;
  font-weight: 500;
  padding-top: 78px;
  margin-bottom: 61px;
}

.construction {
  // background: #F5F5F9;
  .target {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 107px;

    .targetcard {
      display: flex;
      justify-content: center;
      align-items: center;

      .targetinfo {
        .targetinfoheader {
          .targetinftitle {
            padding-left: 16px;
            border-left: 4px solid #2f7ffc;
            font-size: 35px;
            font-weight: 500;
          }

          .targetinftitle2 {
            padding-left: 16px;
            border-left: 4px solid #ffda0a;
            font-size: 20px;
            font-weight: 500;
            color: #858585;
            line-height: 33px;
          }
        }

        .targetinfotext {
          width: 749px;
          font-size: 14px;
          font-weight: 500;
          color: #828282;
          line-height: 28px;

          .text1 {
            margin-top: 50px;
          }

          .text2 {
            margin: 25px 0;
          }
        }
      }

      .targetimg {
        width: 492px;
        height: 583px;
        margin-left: 109px;
      }
    }
  }
}

.purpose {
  background-image: url(../../../assets/imges/presentation/2.png);
  background-size: contain;
  height: 424px;

  color: #fff;

  span {
    margin-left: 293px;
  }

  .purposeheader {
    font-size: 36px;
    font-weight: 500;
    padding-top: 106px;
    text-align: center;
  }

  .purposeinfo {
    width: 768px;
    margin: 0 auto;
    margin-top: 95px;

    .circle {
      width: 14px;
      height: 14px;
      border-radius: 50%;
    }

    .time {
      /deep/.el-step__icon.is-text {
        background-color: red;
        border-color: red;
      }
    }

    // /deep/.el-step__icon.is-text{
    // 	background-color: #72787B;
    // 	border-color: #72787B;
    // }

    /deep/.el-step__line {
      background-color: #d7d8d9;
      // top: 20px
    }
  }
}

.culture {
  .cultureheader {
    width: 80%;
    margin: 0 auto;
    padding: 59px 0;
    font-size: 28px;
    border-bottom: 1px solid #979797;

    .title1 {
      font-family: SourceHanSansCN-Bold, SourceHanSansCN;
      font-weight: bold;
    }

    .arrows {
      color: #bebebe;
      padding-left: 25px;
    }

    .title2 {
      font-weight: 500;
      margin-left: 97px;
      color: #b4b4b4;
    }

    .title2:hover {
      color: #2f7ffc;
      padding-bottom: 15px;
      border-bottom: 6px solid #2f7ffc;
      cursor: pointer;
    }
  }
}
</style>
